/* eslint-disable */

// import { useRuntimeConfig, useCookie } from '#app'
import { default as Axios, AxiosRequestConfig, AxiosInstance } from 'axios'

interface useApiInstance {
  $axios: AxiosInstance
  $request<T = any>(config: AxiosRequestConfig): Promise<T>
  $get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
  $post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
  $put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
  $delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
  $patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
  $options<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
  $head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
}

export function useAxios (): useApiInstance {
  const config = useRuntimeConfig()

  const axiosOptions = {
    baseURL: 'https://strapi.amra-bank.com'
  }

  const axiosExtra = {}

  const createAxiosInstance = (axiosOptions) => {
    // Create new axios instance
    const axios = Axios.create(axiosOptions)
    // @ts-ignore
    axios.CancelToken = Axios.CancelToken
    // @ts-ignore
    axios.isCancel = Axios.isCancel

    // Extend axios proto
    extendAxiosInstance(axios)

    // Intercept to apply default headers
    axios.interceptors.request.use((config) => {
      let headers = { ...axios.defaults.headers.common, ...config.headers }

      config.headers = { ...config.headers, ...headers }

      config.url = encodeURI(config.url)

      config.baseURL = axiosOptions.baseURL

      return config
    })

    return axios
  }

  for (const method of ['request', 'delete', 'get', 'head', 'options', 'post', 'put', 'patch']) {
    // eslint-disable-file prefer-spread
    axiosExtra['$' + method] = function () {
      return this[method].apply(this, arguments).then(res => res && res.data)
    }
  }

  const extendAxiosInstance = (axios) => {
    for (const key in axiosExtra) {
      axios[key] = axiosExtra[key].bind(axios)
    }
  }

  const axios = createAxiosInstance(axiosOptions)

  // @ts-ignore
  return {
    ...axios,
    $axios: axios
  }
}

